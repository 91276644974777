import React from 'react';
import {customColor} from 'shared/utils/colors';
import {
	autocompleteClasses,
	checkboxClasses,
	inputClasses,
	radioClasses,
	selectClasses,
	tabClasses
} from '@mui/joy';
import {extendTheme} from '@mui/joy/styles';
import {ChevronDown} from 'lucide-react';

function ChevronDownIcon() {
	return <ChevronDown height={24} width={24} color="#7A818B" />;
}

/**
 * @description Global theme definition for MUI default Theme
 * @see {@link https://mui.com/joy-ui/customization/approaches MUI reference on good approaches} for further info
 */
const theme = extendTheme({
	colorSchemes: {
		light: {
			palette: {
				customColor: {
					bluePrimary: '#4F92EA',
					borderButton: '#95BEF2',
					activeBgPrimary: '#EDF4FD',
					linkHover: '#3F75BB'
				},
				neutral: {
					plainHoverColor: '#07090C',
					solidHoverBg: '#1B2432',
					solidDisabledColor: '#9FA6AD',
					solidDisabledBg: '#D3D5D8',
					solidColor: '#FFFFFF',
					solidBg: '#222D3E',
					solidActiveBg: '#141B25',
					softHoverBg: '#9FA6AD',
					softDisabledColor: '#9FA6AD',
					softDisabledBg: '#E9EAEC',
					softColor: '#141B25',
					softBg: '#D3D5D8',
					softActiveColor: '#0E1219',
					softActiveBg: '#7A818B',
					plainHoverBg: '#F6F7F8',
					plainDisabledColor: '#D3D5D8',
					plainColor: '#141B25',
					plainActiveBg: '#9FA6AD',
					outlinedHoverBg: '#D3D5D8',
					outlinedDisabledColor: '#9FA6AD',
					outlinedDisabledBorder: '#9FA6AD',
					outlinedColor: '#141B25',
					outlinedBorder: '#D3D5D8',
					outlinedActiveBg: '#9FA6AD',
					mainChannel: 'rgba(99,107,116,0.6)',
					lightChannel: 'rgba(221,231,238,0.6)',
					darkChannel: 'rgba(221,231,238,0.6)'
				},
				background: {
					backdrop: 'rgba(0, 0, 0, 0.50)',
					tooltip: '#222D3E',
					surface: '#FFFFFF',
					popup: '#FFFFFF',
					level3: '#7A818B',
					level2: '#9FA6AD',
					level1: '#D3D5D8',
					body: '#FFFFFF'
				},
				warning: {
					solidHoverBg: '#CB7C0B',
					solidDisabledColor: '#9FA6AD',
					solidDisabledBg: '#D3D5D8',
					solidColor: '#FFFFFF',
					solidBg: '#FE9B0E',
					solidActiveBg: '#985D08',
					softHoverBg: '#FFD79F',
					softDisabledColor: '#9FA6AD',
					softDisabledBg: '#E9EAEC',
					softColor: '#985D08',
					softBg: '#FFEBCF',
					softActiveBg: '#663E06',
					plainHoverBg: '#FFEBCF',
					plainDisabledColor: '#9FA6AD',
					plainColor: '#FE9B0E',
					plainActiveBg: '#FFD79F',
					outlinedHoverBg: '#FFEBCF',
					outlinedDisabledColor: '#9FA6AD',
					outlinedDisabledBorder: '#9FA6AD',
					outlinedColor: '#FE9B0E',
					outlinedBorder: '#FEC36E',
					outlinedActiveBg: '#FFD79F'
				},
				success: {
					solidHoverBg: '#0A7E4E',
					solidDisabledColor: '#9FA6AD',
					solidDisabledBg: '#D3D5D8',
					solidColor: '#FFFFFF',
					solidBg: '#0C9D61',
					solidActiveBg: '#075E3A',
					softHoverBg: '#9ED8C0',
					softDisabledColor: '#9FA6AD',
					softDisabledBg: '#E9EAEC',
					softColor: '#075E3A',
					softBg: '#CEEBDF',
					softActiveColor: '#053F27',
					softActiveBg: '#6DC4A0',
					plainHoverBg: '#CEEBDF',
					plainDisabledColor: '#9FA6AD',
					plainColor: '#0C9D61',
					plainActiveBg: '#9ED8C0',
					outlinedHoverBg: '#CEEBDF',
					outlinedDisabledColor: '#9FA6AD',
					outlinedDisabledBorder: '#9FA6AD',
					outlinedColor: '#0C9D61',
					outlinedBorder: '#6DC4A0',
					outlinedActiveBg: '#9ED8C0'
				},
				primary: {
					solidHoverBg: '#3F75BB',
					solidDisabledColor: '#9FA6AD',
					solidDisabledBg: '#D3D5D8',
					solidColor: '#FFFFFF',
					solidBg: '#4F92EA',
					solidActiveBg: '#2F588C',
					softHoverBg: '#B9D3F7',
					softDisabledColor: '#9FA6AD',
					softDisabledBg: '#E9EAEC',
					softColor: '#2F588C',
					softBg: '#DCE9FB',
					softActiveColor: '#203A5E',
					softActiveBg: '#95BEF2',
					plainHoverBg: '#DCE9FB',
					plainDisabledColor: '#9FA6AD',
					plainColor: '#4F92EA',
					plainActiveBg: '#EDF4FD',
					outlinedHoverBg: '#DCE9FB',
					outlinedDisabledColor: '#9FA6AD',
					outlinedDisabledBorder: '#9FA6AD',
					outlinedColor: '#4F92EA',
					outlinedBorder: '#95BEF2',
					outlinedActiveBg: '#B9D3F7'
				},
				danger: {
					solidHoverBg: '#BD2426',
					solidDisabledColor: '#9FA6AD',
					solidDisabledBg: '#D3D5D8',
					solidColor: '#FFFFFF',
					solidBg: '#EC2D30',
					solidActiveBg: '#8E1B1D',
					softHoverBg: '#F7ABAC',
					softDisabledColor: '#9FA6AD',
					softDisabledBg: '#E9EAEC',
					softColor: '#8E1B1D',
					softBg: '#FBD5D6',
					softActiveColor: '#5E1213',
					softActiveBg: '#F48183',
					plainHoverBg: '#FBD5D6',
					plainDisabledColor: '#9FA6AD',
					plainColor: '#EC2D30',
					plainActiveBg: '#F7ABAC',
					outlinedHoverBg: '#FBD5D6',
					outlinedDisabledColor: '#9FA6AD',
					outlinedDisabledBorder: '#9FA6AD',
					outlinedColor: '#EC2D30',
					outlinedBorder: '#F48183',
					outlinedActiveBg: '#F7ABAC'
				},
				text: {
					tertiary: '#9FA6AD',
					secondary: '#4E5765',
					primary: '#222D3E',
					icon: '#7A818B'
				},
				focusVisible: '#4F92EA',
				divider: '#DDE5F0'
			}
		}
	},
	shadow: {
		sm: '0px 2px 4px 0px #15151514'
	},
	components: {
		JoyButton: {
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					...(ownerState.size === 'md' && {
						fontSize: '14px'
					}),
					...(ownerState.color === 'customColor' &&
						ownerState.variant === 'outlined' && {
							'&:hover': {
								backgroundColor: '#DCE9FB',
								transition: '0.1s'
							},
							color: theme.vars.palette.customColor.bluePrimary,
							border: `1px solid ${theme.vars.palette.customColor.borderButton}`
						})
				})
			}
		},
		JoyLink: {
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					'&:hover': {
						color: theme.vars.palette.customColor.linkHover,
						textDecoration: 'underline'
					}
				})
			}
		},
		JoyCard: {
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					border: 'none',
					borderRadius: 'var(--radius-md, 8px)',
					background: 'var(--background-surface, #FFF)',
					boxShadow:
						'0px 1px 2px 0px rgba(21, 21, 21, 0.08), 0px 2px 4px 0px rgba(21, 21, 21, 0.08)',
					...(ownerState.color === 'customColor' &&
						ownerState.variant === 'outlined' && {
							color: theme.vars.palette.customColor.bluePrimary,
							backgroundColor: '#FFF',
							border: `1px solid ${theme.vars.palette.divider}}`
						})
				})
			}
		},
		JoyAccordionSummary: {
			defaultProps: {
				indicator: (
					<ChevronDownIcon
						sx={{
							color: customColor.bluePrimary
						}}
					/>
				)
			},
			styleOverrides: {
				root: {
					[`& .${selectClasses.indicator}`]: {
						transition: '0.2s',
						[`&.${selectClasses.expanded}`]: {
							transform: 'rotate(-180deg)'
						}
					}
				}
			}
		},
		JoySelect: {
			defaultProps: {
				color: 'neutral',
				variant: 'outlined',
				indicator: ChevronDownIcon()
			},
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					minHeight: 40,
					[`& .${selectClasses.indicator}`]: {
						transition: '0.2s',
						[`&.${selectClasses.expanded}`]: {
							transform: 'rotate(-180deg)'
						}
					},
					...((ownerState.value === null ||
						(Array.isArray(ownerState.value) && ownerState.value.length === 0)) && {
						color: '#9FA6AD'
					}),
					...(ownerState.disabled && {
						color: theme.colorSchemes.light.palette.neutral.softDisabledColor,
						backgroundColor: theme.colorSchemes.light.palette.primary.softDisabledBg,
						borderColor: theme.colorSchemes.light.palette.neutral.outlinedDisabledBorder
					})
				})
			}
		},
		JoyInput: {
			defaultProps: {
				variant: 'outlined',
				color: 'neutral'
			},
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					fontSize: '14px',
					minHeight: 40,
					'& input[type=number]::-webkit-outer-spin-button': {
						'-webkit-appearance': 'none'
					},
					'& input[type=number]::-webkit-inner-spin-button': {
						'-webkit-appearance': 'none'
					},
					'& .MuiInput-input::placeholder': {
						fontSize: '14px',
						color: '#9FA6AD',
						opacity: '64%'
					},
					...(ownerState.disabled && {
						color: theme.colorSchemes.light.palette.neutral.softDisabledColor,
						backgroundColor: theme.colorSchemes.light.palette.primary.softDisabledBg,
						borderColor: theme.colorSchemes.light.palette.neutral.outlinedDisabledBorder
					})
				})
			}
		},
		JoyAutocomplete: {
			defaultProps: {
				color: 'neutral',
				variant: 'outlined',
				popupIcon: (
					<ChevronDown
						style={{
							width: 24,
							height: 24,
							color: '#7A818B'
						}}
					/>
				),
				slotProps: {
					root: {
						sx: {
							display: 'flex',
							alignItems: 'center'
						}
					},
					popupIndicator: {
						sx: {
							transition: 'transform 0.2s ease-in-out'
						}
					}
				}
			},
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					fontSize: '14px',
					minHeight: 40,
					[`& .${autocompleteClasses.popupIndicator}`]: {
						transition: '0.2s',
						[`&.${autocompleteClasses.popupIndicatorOpen}`]: {
							transform: 'rotate(-180deg)'
						}
					},
					'& .MuiAutocomplete-input::placeholder': {
						color: '#9FA6AD',
						opacity: '64%',
						fontSize: '14px'
					},
					...(ownerState.disabled && {
						color: theme.colorSchemes.light.palette.neutral.softDisabledColor,
						backgroundColor: theme.colorSchemes.light.palette.primary.softDisabledBg,
						borderColor: theme.colorSchemes.light.palette.neutral.outlinedDisabledBorder
					})
				}),
				listbox: {
					fontSize: '14px'
				}
			}
		},
		JoyTextarea: {
			defaultProps: {
				color: 'neutral',
				variant: 'outlined'
			},
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					height: 'initial !important',
					minHeight: 40,

					'& .MuiTextarea-textarea::placeholder': {
						color: '#9FA6AD',
						opacity: '64%'
					},
					...(ownerState.disabled && {
						color: theme.colorSchemes.light.palette.neutral.softDisabledColor,
						backgroundColor: theme.colorSchemes.light.palette.primary.softDisabledBg,
						borderColor: theme.colorSchemes.light.palette.neutral.outlinedDisabledBorder
					})
				})
			}
		},
		JoyTab: {
			styleOverrides: {
				root: {
					[`&:not(.${tabClasses.selected})`]: {
						color: 'rgba(20, 27, 37, 1)'
					},
					[`&.${tabClasses.selected}`]: {
						backgroundColor: 'transparent',
						fontWeight: '600'
					}
				}
			}
		},
		JoyTabs: {
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					backgroundColor: 'transparent'
				})
			}
		},
		JoySnackbar: {
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					fontWeight: 500
				})
			}
		},
		JoyTypography: {
			styleOverrides: {
				root: {
					paddingY: 0
				}
			}
		},
		JoyRadio: {
			defaultProps: {
				color: 'primary',
				variant: 'outlined'
			},
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					[`&.${inputClasses.disabled}`]: {
						[`.${radioClasses.radio}`]: {
							backgroundColor: 'rgba(211, 213, 216, 1)',
							borderColor: 'rgba(211, 213, 216, 1)'
						}
					},
					...(ownerState.checked && {}),
					...(ownerState.disabled && {
						textDecorationColor: 'yellow',
						color: theme.colorSchemes.light.palette.text.tertiary
					})
				})
			}
		},
		JoyCheckbox: {
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					[`&.${checkboxClasses.action}`]: {
						borderColor: '#95BEF2',
						color: '#4F92EA',
						backgroundColor: '#B9D3F7',
						...(ownerState.checked && {
							background: 'red'
						})
					},
					'& .MuiCheckbox-checkbox': {
						borderColor: theme.colorSchemes.light.palette.neutral.outlinedBorder,
						backgroundColor: ownerState.disabled
							? theme.colorSchemes.light.palette.primary.softDisabledBg
							: theme.colorSchemes.light.palette.background.surface,
						'&:hover': {
							backgroundColor: theme.colorSchemes.light.palette.primary.outlinedHoverBg,
							borderColor: theme.colorSchemes.light.palette.primary.outlinedHoverBorder
						},
						'&.Mui-checked, &.MuiCheckbox-indeterminate': {
							borderColor: theme.colorSchemes.light.palette.primary.outlinedBorder,
							color: theme.colorSchemes.light.palette.primary.outlinedColor,
							backgroundColor: ownerState.disabled
								? theme.colorSchemes.light.palette.primary.softDisabledBg
								: theme.colorSchemes.light.palette.primary.outlinedActiveBg
						}
					}
				})
			}
		}
	}
});

export default theme;
