const qs = require('qs');
const getURL = (protocol = 'https') => {
	let url = window.location.href.split('/')[2];
	console.log(url);
	url = url.replace('www.', '');
	let splitUrl = url.split('.');
	let possibleMainUrl = [splitUrl[0], splitUrl[1]];
	if (possibleMainUrl.includes('portalsekolah')) {
		return `${protocol}://backend.portalsekolah.co.id`;
	} else if (possibleMainUrl.includes('promisedland')) {
		return `${protocol}://backend-v2.promisedland.id`;
	} else {
		return `${protocol}://backend-v2.portalsiswa.id`;
	}
};
export const baseURL = (() => {
	return getURL();
})();

export const socketBaseURL = (() => {
	return getURL('wss');
})();

export const homeUrl = (() => {
	let url = window.location.href.split('/')[2];
	url = url.replace('www.', '');
	let splitUrl = url.split('.');
	let possibleMainUrl = [splitUrl[0], splitUrl[1]];

	if (possibleMainUrl.includes('portalsekolah')) {
		return `portalsekolah.co.id`;
	} else if (possibleMainUrl.includes('promisedland')) {
		return `promisedland.id`;
	} else if (possibleMainUrl.includes('portalsiswa')) {
		return `portalsiswa.id`;
	} else if (possibleMainUrl?.some((i) => i.includes('localhost'))) {
		return `localhost`;
	} else {
		return `portalsekolah.co.id`;
	}
})();

// const isNewApiEnabled = false;

export const EPStore = {
	getNewAccessToken: {
		url: 'api/token/refresh/',
		method: 'POST'
	},
	getUserViaToken: {
		url: 'api/auth/loggedinuserDetailsNew/',
		method: 'GET'
	}, // TECHDEBT(PSM-1206): need to implement flag here
	// getUserViaToken: {
	//   url: isNewApiEnabled ? "api/auth/loggedinuserDetailsNew/" : "api/auth/loggedinuserDetails/",
	//   method: "GET"
	// },
	userLogin: {
		url: 'api/auth/login/',
		method: 'POST'
	},
	signUp: {
		url: 'api/auth/register/',
		method: 'POST'
	},
	sendResetLink: {
		url: 'api/auth/send-reset-password-link/',
		method: 'POST'
	},
	resetPassword: {
		url: 'api/auth/reset-password/',
		method: 'POST'
	},
	logout: {
		url: 'api/auth/logout/',
		method: 'POST'
	},
	listGrades: {
		url: 'api/course/listGrades/',
		method: 'GET'
	},
	listSchools: {
		url: 'api/school_portal/schoolListing/',
		method: 'GET'
	},
	notifyAdmin: {
		url: 'api/auth/reset-password-request-to-admin/',
		method: 'POST'
	},
	verifyRegistration: {
		url: 'api/auth/verify-registration/',
		method: 'POST'
	},
	getListSchool: {
		url: 'api/v2/school/schools/',
		method: 'GET'
	},
	loginV2: {
		url: 'api/v2/auth/login/',
		method: 'POST'
	}
};

export const generateRequestOptions = (key, options = {}) => {
	if (!key) {
		return null;
	}

	if (options && options.hasOwnProperty('queryParams')) {
		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty('baseURL')
				? `${EPStore[key].baseURL}/${EPStore[key].url}?${qs.stringify(options.queryParams)}`
				: `${baseURL}/${EPStore[key].url}?${qs.stringify(options.queryParams)}`
		};
	}
	if (options && options.hasOwnProperty('urlParams')) {
		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty('baseURL')
				? `${EPStore[key].baseURL}/${EPStore[key].url}${options.urlParams}`
				: `${baseURL}/${EPStore[key].url}${options.urlParams}`
		};
	}
	return {
		...EPStore[key],
		url: EPStore[key].hasOwnProperty('baseURL')
			? `${EPStore[key].baseURL}/${EPStore[key].url}`
			: `${baseURL}/${EPStore[key].url}`
	};
};
export const generateURL = (key) => {
	return `${homeUrl}/${EPStore[key].url}`;
};
